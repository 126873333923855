import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "spock"
    }}>{`S.P.O.C.K.`}</h1>
    <h2 {...{
      "id": "project-outline"
    }}>{`Project outline`}</h2>
    <p>{`S.P.O.C.K. is a platform that provides an interactive digital experience for `}<strong parentName="p">{`visitors`}</strong>{` of a `}<strong parentName="p">{`location`}</strong>{` through a mobile app.
The mobile app can detect proximity to a `}<strong parentName="p">{`location`}</strong>{` using the GEO location (fence) of the `}<strong parentName="p">{`location`}</strong>{`, `}<strong parentName="p">{`items`}</strong>{` within the `}<strong parentName="p">{`location`}</strong>{`
are equipped with a BLE to enable fetching relevant digital content into the mobile app. Above that, `}<strong parentName="p">{`visitors`}</strong>{` can
send messages to employees of the `}<strong parentName="p">{`location`}</strong>{`.`}</p>
    <p>{`For more information about all entities and actors, see `}<a parentName="p" {...{
        "href": "#taxonomy"
      }}>{`taxonomy`}</a></p>
    <h2 {...{
      "id": "architecture"
    }}>{`Architecture`}</h2>
    <h2 {...{
      "id": "coding--techniques"
    }}>{`Coding & techniques`}</h2>
    <ul>
      <li parentName="ul">{`Car dealer app in `}<strong parentName="li">{`Flutter`}</strong></li>
      <li parentName="ul">{`Admin panel in `}<strong parentName="li">{`react.js`}</strong></li>
      <li parentName="ul">{`API's in `}<strong parentName="li">{`Kotlin`}</strong></li>
      <li parentName="ul">{`Helm for deployments`}</li>
    </ul>
    <h2 {...{
      "id": "environments"
    }}>{`Environments`}</h2>
    <h2 {...{
      "id": "repositories"
    }}>{`Repositories`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Repository`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Spock Project`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Root project repository containing all components`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-project"
            }}>{`https://github.com/HybrIT-dev/spock-project`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor App`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`App that end users will use to visit and watch POI's`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-visitor-app"
            }}>{`https://github.com/HybrIT-dev/spock-visitor-app`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Asset Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for assets like images, movies, audio files, etc..`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-asset-service"
            }}>{`https://github.com/HybrIT-dev/spock-asset-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User-service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for users, proxies requests to keycloak.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-user-service"
            }}>{`https://github.com/HybrIT-dev/spock-user-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Admin-panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Front-end for Backend/API endpoints`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-admin-panel"
            }}>{`https://github.com/HybrIT-dev/spock-admin-panel`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-events-service"
            }}>{`https://github.com/HybrIT-dev/spock-events-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Configuration Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for configuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-configuration-service"
            }}>{`https://github.com/HybrIT-dev/spock-configuration-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for search`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-search-service"
            }}>{`https://github.com/HybrIT-dev/spock-search-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Indexing Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for indexing searchable entities`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-indexing-service"
            }}>{`https://github.com/HybrIT-dev/spock-indexing-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Statistics Service`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API for visitor statistics`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-statistics-service"
            }}>{`https://github.com/HybrIT-dev/spock-statistics-service`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Api Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API Gateway`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-api-gateway"
            }}>{`https://github.com/HybrIT-dev/spock-api-gateway`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Keycloak`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DevOps setup to deploy and configure the Keycloak instance`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-idp-keycloak"
            }}>{`https://github.com/HybrIT-dev/spock-idp-keycloak`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Infrastructure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Infrastructure-as-code for our spock K8s cluster.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-infrastructure-cluster"
            }}>{`https://github.com/HybrIT-dev/spock-infrastructure-cluster`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gradle Bom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bill of material for S.P.O.C.K.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-bom"
            }}>{`https://github.com/HybrIT-dev/spock-bom`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gradle Plugins`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Build plugins for S.P.O.C.K.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-gradle-plugins"
            }}>{`https://github.com/HybrIT-dev/spock-gradle-plugins`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Library kotlin extensions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Library for common extensions used in S.P.O.C.K. backends`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-kotlin-extensions"
            }}>{`https://github.com/HybrIT-dev/spock-kotlin-extensions`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Library integration test`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Library for integration tests S.P.O.C.K.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-integration-test"
            }}>{`https://github.com/HybrIT-dev/spock-integration-test`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Library elasticsearch support`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Library for elasticsearch in S.P.O.C.K. backends`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-elasticsearch-support"
            }}>{`https://github.com/HybrIT-dev/spock-elasticsearch-support`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Library api support`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Library for documentation and security of S.P.O.C.K. backends`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-api-support"
            }}>{`https://github.com/HybrIT-dev/spock-api-support`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Azuredevops Templates`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pipeline templates for S.P.O.C.K. pipeline builds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-azuredevops-templates"
            }}>{`https://github.com/HybrIT-dev/spock-azuredevops-templates`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cicd utility`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Python utility script for CI/CD scripting`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-cicd-utility"
            }}>{`https://github.com/HybrIT-dev/spock-cicd-utility`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Platform Deployment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Umbrella helm chart for the whole of the spock platform`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-umbrella-chart"
            }}>{`https://github.com/HybrIT-dev/spock-umbrella-chart`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Legal Statements`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NGINX deployment for serving legal statement pages`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-legal-statements"
            }}>{`https://github.com/HybrIT-dev/spock-legal-statements`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Product Website`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`WordPress product website`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-product-website"
            }}>{`https://github.com/HybrIT-dev/spock-product-website`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock CMS`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Back-end/API CMS for locations, beacons, pages, assets`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-cms"
            }}>{`https://github.com/HybrIT-dev/spock-cms`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock Common Charts`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Common helm charts for deployment usage`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-common-charts"
            }}>{`https://github.com/HybrIT-dev/spock-common-charts`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Car dealer`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`App that end users will use to visit and watch POI's`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/cardealer"
            }}>{`https://github.com/HybrIT-dev/cardealer`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock Parent`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Parent project to deploy client/api/infrastructure`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock_parent"
            }}>{`https://github.com/HybrIT-dev/spock_parent`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock docker dev`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Local docker setup with all images you need to start development`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-docker-dev"
            }}>{`https://github.com/HybrIT-dev/spock-docker-dev`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock API`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated, functionality moved to spock-cms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock_api"
            }}>{`https://github.com/HybrIT-dev/spock_api`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><del parentName="td">{`Spock Beacon Service`}</del></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deprecated, functionality moved to spock-cms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/HybrIT-dev/spock-beacon-service"
            }}>{`https://github.com/HybrIT-dev/spock-beacon-service`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-started-with-development"
    }}>{`Getting started with development`}</h2>
    <h3 {...{
      "id": "git-secret"
    }}>{`git secret`}</h3>
    <p>{`Apart from the front-end repository, all other repositories have files containing secrets (connection strings, passwords etc). They are encrypted with git secret. Make sure that you are added to the repositories and have the ability to run `}<inlineCode parentName="p">{`git secret reveal`}</inlineCode>{`. You can see who is able to add you via `}<inlineCode parentName="p">{`git secret who knows`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "src-getting-started-gitsecret#show-added-people"
      }}>{`git secret guide`}</a>{`.`}</p>
    <h3 {...{
      "id": "platform"
    }}>{`Platform`}</h3>
    <p><img alt="Alt text" src={require("./blob/spock.png")} /></p>
    <h3 {...{
      "id": "event-api"
    }}>{`EVENT API`}</h3>
    <p>{`The event API is responsible for handling all messages from and to firebase. The CMS web application will use sockets to have an up to date view of the current events. Next to the Kotlin implementation, it uses his own DB schema at postgress to isolate the event functionality as a microservice.`}</p>
    <p><img alt="Alt text" src={require("./blob/Spock-eventAPI.png")} /></p>
    <h5 {...{
      "id": "event-use-cases"
    }}>{`EVENT USE CASES`}</h5>
    <p>{`There are 2 use cases specified for the EVENTS service:
1. Visitor asks for coffee
1. Visitor asks a question`}</p>
    <h5 {...{
      "id": "1-coffee-use-case"
    }}>{`1. Coffee use case`}</h5>
    <p><img alt="Alt text" src={require("./blob/spock-usecase-coffee.png")} /></p>
    <h5 {...{
      "id": "2-question-use-case"
    }}>{`2. Question use case`}</h5>
    <p><img alt="Alt text" src={require("./blob/spock-usecase-question.png")} /></p>
    <h3 {...{
      "id": "platform-in-detail"
    }}>{`Platform in detail`}</h3>
    <p><img alt="Alt text" src={require("./blob/spock-platform_incl-use-case-layers.png")} /></p>
    <blockquote>
      <p parentName="blockquote">{`Use the source of this image in `}<a parentName="p" {...{
          "href": "https://diagrams.net"
        }}>{`draw.io`}</a>{` to edit the picture and export it with a copy of the diagram for further use.
The images used to display the use cases below are included in the layers of this image.`}</p>
    </blockquote>
    <h2 {...{
      "id": "taxonomy"
    }}>{`Taxonomy`}</h2>
    <h3 {...{
      "id": "usage-domains"
    }}>{`Usage domains`}</h3>
    <p>{`The platform can be divided into the following 3 usage domains:`}</p>
    <ol>
      <li parentName="ol">{`Visitor`}</li>
      <li parentName="ol">{`Customer`}</li>
      <li parentName="ol">{`Provider`}</li>
    </ol>
    <p>{`The table below describes these domains in depth:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Domain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related component`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Related to visiting physical locations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Related to the ownership of physical locations`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Web Dashboard`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Related to provisioning of the platform to customers`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Web Dashboard`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "actors"
    }}>{`Actors`}</h3>
    <p>{`The following actors are currently defined:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Actor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Domain`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Person visiting locations and using the Mobile App`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee of the customer that adds or removes locations`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee of the location that configures a location`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location admin`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee of the location that manages users`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Employee of provider that adds or removes customers`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "entities"
    }}>{`Entities`}</h3>
    <p>{`The following entities are currently defined:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Entity`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A physical, enclosed space`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A spot within a location that is equipped with a BLE device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Digital content that can be attached to an item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rich content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Advanced content like images, movies, audio`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions that initiate interaction with location users`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "authorization-matrices"
    }}>{`Authorization matrices`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Denied`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowed with scope`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "administration"
    }}>{`Administration`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PROVIDER`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create provider user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View provider user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit provider user (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete provider user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CUSTOMER`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create customer user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View customer user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit customer user (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete customer user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LOCATION`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create location user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View location user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit location user (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete location user`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`(*) User details can not be changed by other users.
Editing of locations is limited to name of the location only in the `}<strong parentName="p">{`Administration section`}</strong>{` `}</p>
    </blockquote>
    <h3 {...{
      "id": "configuration"
    }}>{`Configuration`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DEFAULT CONFIG`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create default config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View default config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update default config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete default config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PROVIDER CONFIG`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create provider config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View provider config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update provider config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete provider config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CUSTOMER CONFIG`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create customer config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View customer config`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update customer config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete customer config (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`(*) Restriction / Creation based on the providerId / CustomerId from the authenticated user `}</p>
    </blockquote>
    <h3 {...{
      "id": "assets-media"
    }}>{`Assets (Media)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`PROVIDER ASSETS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create provider asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View provider asset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update provider asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete provider asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`CUSTOMER ASSETS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create customer asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View customer asset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update customer asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete customer asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`LOCATION ASSETS`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create location asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View location asset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update location asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete location asset (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`(*) Restriction / Creation based on the providerId / CustomerId / LocationId from the authenticated user
Removal of assets only if not in use.`}</p>
    </blockquote>
    <h3 {...{
      "id": "events"
    }}>{`Events`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create response event`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View message events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View interaction events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete events`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`If the location ID is not available in the users scope, events can not be viewed`}</p>
    </blockquote>
    <h3 {...{
      "id": "indexing"
    }}>{`Indexing`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reindex (location / Item)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Index Item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Index Item attribute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Index Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Index Location address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Updating index for a specific object (item, location etc.) is limited to calls from the location service`}</p>
    </blockquote>
    <h3 {...{
      "id": "statistics"
    }}>{`Statistics`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View total visitors per hour`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View visitors near item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`If the location ID is not available in the users scope, events can not be viewed`}</p>
    </blockquote>
    <h3 {...{
      "id": "locations"
    }}>{`Locations`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View all locations (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View location (including address and details)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create location address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update location address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update location details (includes image)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`(*) Always limited to scope of the user.`}</p>
    </blockquote>
    <h3 {...{
      "id": "actions"
    }}>{`Actions`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View all actions (*)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create geo action (1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update geo action (1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create location interaction (1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update location interaction (1)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create beacon action (2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update beacon action (2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create item interaction (2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item interaction (2)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`(*) Always limited to scope of the user.`}</p>
      <p parentName="blockquote">{`(1) Linked to a location`}</p>
      <p parentName="blockquote">{`(2) Linked to an item`}</p>
    </blockquote>
    <h3 {...{
      "id": "beacons"
    }}>{`Beacons`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View all beacons`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create beacon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update beacon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete beacon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Beacons only exist for locations`}</p>
    </blockquote>
    <h3 {...{
      "id": "items"
    }}>{`Items`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View all items`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item attribute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "item-types"
    }}>{`Item types`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Owner`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location Admin`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Location User`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View all item types`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create item type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create item type attribute category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item type attribute category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete item type attribute category`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Create item type attribute (within a category)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Update item type attribute (within a category)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete item type attribute (within a category)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Delete item type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: Y `}<br />{` UI: Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`API: N `}<br />{` UI: N`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`When creating an item type, the scope of the user determines the authorization level
As owner: only owners can edit (others can view)
As provider: only providers can edit (others that belong to the provider can view)
As customer: only customers can edit (others that belong to the customer can view)
As location admin: only location admins can edit (others that belong to the location can view)`}</p>
    </blockquote>
    <h2 {...{
      "id": "deployment"
    }}>{`Deployment`}</h2>
    <p>{`The deployment uses a single Helm Chart that is updated with the following process:
`}<img alt="spock-platform-deployment" src={require("./blob/spock-platform-deployment.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      